import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export function createRouter () {
  return new VueRouter({
    mode: 'history',
    fallback: false,
    routes: [
      {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index-chunk" */ '../views/index.vue'),
        meta: {
          ssr: true,
        }
      }
    ]
  })
}
