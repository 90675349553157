import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export function createStore () {
  return new Vuex.Store({
    state: {
      hasInit: false,
      item: {}, // 商品信息
      banner: [], // 轮播图
      mediaInterview: {}, // 媒体采访
      mediaReports: [], // 媒体报道
      sick: [], // 重疾文章
      medical: [], // 医疗文章
      accident: [], // 意外文章
      life: [] // 寿险文章
    },
    getters :{
    },
    mutations: {
      updateState (state, param) {
        if (param.constructor === Object) {
          state[param.key] = param.value
        } else if (param.constructor === Array) {
          for (let i = 0; i < param.length; i++) {
            state[param[i].key] = param[i].value
          }
        }
      }
    },
    actions: {
      getFirstScreen ({ commit }, { vueInstance, postData = {} }) {
        return vueInstance.$$fetch({ 
          url: '/Index/getPcFirst',
          data: {
            ...postData
          },
          cache: {
            cacheable: true,
            expireTime: 1000 * 60 * 2, //数据2分钟有效
            shouldDataCache: function (data) { return Number(data.ret) === 0 }
          }
        }).then(res => {
          const { ret, data, msg } = res || {}
          if (Number(ret) === 0) {
            commit('updateState', [
              { key: 'hasInit', value: true },
              { key: 'item', value: data.item || {} },
              { key: 'banner', value: data.banner || [] },
              { key: 'mediaInterview', value: data.mediaInterview || {} },
              { key: 'mediaReports', value: data.mediaReports || [] },
              { key: 'sick', value: data.sick || [] },
              { key: 'medical', value: data.medical || [] },
              { key: 'accident', value: data.accident || [] },
              { key: 'life', value: data.life },
            ])
            return Promise.resolve()
          } else {
            return Promise.reject(msg)
          } 
        }).catch(e => {
          return Promise.reject(e)
        })
      },
      getPcIndexData ({ commit }, { vueInstance }) {
        return vueInstance.$$fetch({ 
          url: '/Index/getPcIndexData'
        }).then(res => {
          const { ret, data, msg } = res || {}
          if (Number(ret) === 0) {
            commit('updateState', [
              { key: 'hasInit', value: true },
              { key: 'item', value: data.item || {} },
              { key: 'banner', value: data.banner || [] },
              { key: 'mediaInterview', value: data.mediaInterview || {} },
              { key: 'mediaReports', value: data.mediaReports || [] },
              { key: 'sick', value: data.sick || [] },
              { key: 'medical', value: data.medical || [] },
              { key: 'accident', value: data.accident || [] },
              { key: 'life', value: data.life }
            ])
            return Promise.resolve()
          } else {
            return Promise.reject(msg)
          } 
        }).catch(e => {
          return Promise.reject(e)
        })
      },
    }
  })
}
