import './assets/css/global.scss'
import 'swiper/swiper-bundle.css'
import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import { sync } from 'vuex-router-sync'
import VueLazyLoad from 'vue-lazyload'
import globalCDN from './mixin/index'
Vue.config.productionTip = false
Vue.use(VueLazyLoad, {
  loading: '//sslstatic.xiaoyusan.com/pc/images/index/lazy.gif'
})
Vue.mixin(globalCDN)

export default {
  createStoreAndRouter () {
    if (!process.server) {
      const VueAwesomeSwiper = require('vue-awesome-swiper')
      Vue.use(VueAwesomeSwiper)
    }
    const store = createStore()
    const router = createRouter()
    sync(store, router)
  
    return { router, store }       
  },
  createApp (context, router, store) {
    const app = new Vue(Object.assign({}, context.rootOptions, {
      router,
      store,
      render: h => h(App)
    }))
  
    return { app, router, store }
  }
}
